import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import '../../style/base.css';
import App from './App';

export default () => (
  <HelmetProvider>
    <BrowserRouter basename="/explore">
      <App />
    </BrowserRouter>
  </HelmetProvider>
);
