export default function SnackbarContent(theme) {
  return {
    MuiSnackbarContent: {
      defaultProps: {
      },
      styleOverrides: {
        message: {
          fontSize: theme.typography.body1.fontSize,
        }
      }
    }
  };
}
