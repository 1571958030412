import React from 'react';
import classnames from 'classnames';
import { useQuery } from '@apollo/client';
import { GET_EXPLORE } from '@apis/ExploreApi';
import { getImageUrl, getReadableTime, getFaviconUrl } from '@utils/helper';
import Logo from '@components/Logo';
import { BookmarkIcon, ClockIcon } from '@heroicons/react/24/outline';
import { getEmojiAndName } from '@utils/helper';

const CARD_ROTATE = {
  0: 'rotate-0 z-30',
  1: 'rotate-3 z-20',
  2: '-rotate-6 z-10'
}

export default () => {
  const { loading, error, data } = useQuery(GET_EXPLORE, {
    variables: {}
  });

  return (
    <main className="max-w-screen-2xl	mx-auto pb-12">
      <div
        className="px-10 max-xl:px-8 max-lg:px-6 max-md:px-4 max-[414px]:px-2"
      >
        <header className="flex items-center justify-center pt-16 pb-12 max-lg:pt-10 max-lg:pb-10 max-sm:pt-8 max-sm:pb-8">
          <a href="/">
            {/* <Logo viewBox="0 0 48 48" className="text-4xl max-sm:text-3xl" /> */}
            <img src="//assets.kiipu.com/img/logo.png?v=230723" className="w-40 max-lg:w-36 max-sm:w-32" />
          </a>
        </header>
        <h1 className="text-3xl font-normal text-center pb-12 max-lg:pb-10 max-sm:pb-8 max-sm:text-2xl">发现有趣的收藏夹</h1>
        {
          loading ? (
            <div role="status" className="flex items-center justify-center py-8">
              <svg aria-hidden="true" className="w-6 h-6 text-gray-200 animate-spin" style={{ fill: 'rgb(251, 163, 0)' }} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            data?.explore ? (
              <div className="grid gap-4 grid-cols-4 max-xl:gap-3 max-lg:grid-cols-3 max-lg:gap-2 max-md:gap-1 max-[540px]:grid-cols-2 max-[414px]:gap-0 max-[320px]:grid-cols-1">
                {
                  data.explore.map((d) => {
                    const [emoji, displayName] = getEmojiAndName(d.collection?.name);

                    return (
                      <a
                        target="_blank"
                        href={`/s/${d.collectionId}`}
                        className="p-4 max-md:p-2 rounded-lg group hover:bg-gray-50 relative"
                        style={{ WebkitTapHighlightColor: 'transparent' }}
                      >
                        <div style={{ paddingBottom: '6%' }}>
                          <div className="font-medium whitespace-nowrap text-ellipsis overflow-hidden">{emoji && <span style={{ marginRight: '8px' }}>{emoji}</span>}{displayName}</div>
                        </div>
                        {
                          d.collection?.bookmarks?.length > 0 ? (
                            <div className="relative pt-[56.25%]"
                            >
                              {
                                d.collection.bookmarks.slice(0, 3).map((bookmark, idx) => {
                                  return (
                                    <div className={classnames('absolute top-0 w-full h-full overflow-clip rounded-lg border border-gray-200 bg-gray-50 shadow-lg transition-transform', CARD_ROTATE[idx])}>
                                      {
                                        bookmark.image ? (
                                          <img
                                            crossOrigin='anonymous'
                                            src={getImageUrl(bookmark.image, bookmark.url, 'cover')}
                                            className="w-full h-full object-cover"
                                          />
                                        ) : (
                                          <img
                                            crossOrigin='anonymous'
                                            src={getFaviconUrl(bookmark.url)}
                                            className="absolute top-1/2 left-1/2 w-[24%] ml-[-12%] mt-[-12%]"
                                          />
                                        )
                                      }
                                    </div>
                                  )
                                })
                              }
                            </div>
                          ) : null
                        }
                        <div style={{ paddingTop: '10%' }}>
                          <div className="flex mb-2">
                            <div className="text-gray-500 text-xs line-clamp-2 flex items-center">
                              <BookmarkIcon className="h-3 w-3 mr-1" />
                              {d.collection.count}
                            </div>
                            <div className="text-gray-500 text-xs line-clamp-2 flex items-center ml-4">
                              <ClockIcon className="h-3 w-3 mr-1" />
                              {getReadableTime(d.collection.bookmarks[0].updatedAt)}
                            </div>
                          </div>
                          <div className="text-sm line-clamp-2 leading-6">{d.description}</div>
                        </div>
                      </a>
                    )
                  })
                }
              </div>
            ) : null
          )
        }
      </div>
      <footer className="flex flex-col items-center justify-center mt-16">
        <div className="flex items-center text-sm text-gray-500 mt-8">
          <div>©2024 Kiipu</div>
          <a target="_blank" href="/explore" className="hover:underline ml-8">发现</a>
          <i className="inline-block border-r border-r-gray-100 h-3 pl-4 mr-4"></i>
          <button id="mega-menu-dropdown-button" data-dropdown-toggle="mega-menu-dropdown" className="hover:underline">
            联系我们
          </button>
        </div>
        <div id="mega-menu-dropdown" className="absolute z-100 grid hidden w-auto grid-cols-2 text-sm bg-white border border-gray-100 rounded-lg shadow-md">
          <div className="p-4 pb-0 text-gray-900 md:pb-4 dark:text-white">
            <ul className="space-y-4" aria-labelledby="mega-menu-dropdown-button">
              <li>
                <a target="_blank" href="https://twitter.com/KiipuApp" className="text-gray-500 hover:underline">
                  X
                </a>
              </li>
              <li>
                <a target="_blank" href="https://discord.gg/u8PKkUYVce" className="text-gray-500 hover:underline">
                  Discord
                </a>
              </li>
              <li>
                <a target="_blank" href="https://weibo.com/u/7858148641" className="text-gray-500 hover:underline">
                  微博
                </a>
              </li>
            </ul>
          </div>
          <div class="p-4">
            <ul class="space-y-4">
              <li>
                <a target="_blank" href="https://v.douyin.com/idqEerUX/" className="text-gray-500 hover:underline">
                  抖音
                </a>
              </li>
              <li>
                <a target="_blank" href="https://www.xiaohongshu.com/user/profile/64c72e3a000000000e026e37" className="text-gray-500 hover:underline">
                  小红书
                </a>
              </li>
              <li>
                <a target="_blank" href="https://okjk.co/jRzy9x" className="text-gray-500 hover:underline">
                  即刻
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </main>
  )
}
