export default function Tooltip(theme) {
  return {
    MuiTooltip: {
      defaultProps: {
        placement: 'top',
        enterDelay: 400,
        PopperProps: {
          modifiers: [{
            name: 'offset',
            options: {
              offset: [0, -12],
            },
          }, ],
        }
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.text.primary,
          boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 5px 10px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
          fontWeight: theme.typography.fontWeightRegular,
          fontSize: 13,
        }
      }
    }
  };
}
