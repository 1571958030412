export default function IconButton(theme) {
  return {
    MuiIconButton: {
      defaultProps: {
      },

      styleOverrides: {
        root: {
        },
        sizeSmall: {
          fontSize: theme.typography.pxToRem(12),
          padding: 3,
        },
        sizeMedium: {
          fontSize: theme.typography.pxToRem(18),
          padding: 5,
        },
        sizeLarge: {
          fontSize: theme.typography.pxToRem(24),
          padding: 8,
        },
      }
    }
  };
}
