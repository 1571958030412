import {
  useMemo
} from 'react';
import {
  createTheme,
  useTheme
} from '@mui/material/styles';
import colors from '../assets/scss/_themes-vars.module.scss';
import componentsOverride from './overrides';
import palette from './palette';
import typography from './typography';

export default function ThemeConfig(customization) {
  // const themess = useTheme();
  // const color = colors;

  // const themeOption = {
  //     colors: color,
  //     heading: color.grey900,
  //     paper: color.paper,
  //     backgroundDefault: color.paper,
  //     background: color.primaryLight,
  //     darkTextPrimary: color.grey700,
  //     darkTextSecondary: color.grey500,
  //     textDark: color.grey900,
  //     menuHoverBack: color.grey50,
  //     menuSelected: color.secondaryDark,
  //     menuSelectedBack: color.secondaryLight,
  //     divider: color.grey200,
  //     customization
  // };

  // const themeOptions = {
  //     direction: 'ltr',
  //     palette: themePalette(themeOption),
  //     mixins: {
  //         toolbar: {
  //             minHeight: '48px',
  //             padding: '16px',
  //             '@media (min-width: 600px)': {
  //                 minHeight: '48px'
  //             }
  //         }
  //     },
  //     typography: themeTypography(themeOption)
  // };

  const themeOptions = useMemo(() => ({
    palette,
    typography,
    mixins: {
      toolbar: {
        minHeight: 48,
        '@media (min-width:1200px)': {
          minHeight: 56,
        },
      }
    }
  }), []);

  const theme = createTheme(themeOptions);

  theme.components = componentsOverride(theme);

  return theme;
};
