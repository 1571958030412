import React from 'react';
import PropTypes from 'prop-types';
import { Box, SvgIcon } from '@mui/material';
// import LogoImg from './logo.svg';

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo(props) {
  // return <Box component="img" src={LogoImg} sx={{ width: 24, height: 24, ...sx }} />;
  return (
    <SvgIcon { ...props }>
      <defs>
        <linearGradient x1="10.195%" y1="19.384%" x2="82.115%" y2="87.926%" id="linearGradient-mr9ul6bi4--1">
          <stop stop-color="#FFCD76" offset="0%"></stop>
          <stop stop-color="#DD8923" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="19.633%" y1="-153.678733%" x2="87.33%" y2="303.121615%" id="linearGradient-mr9ul6bi4--2">
          <stop stop-color="#653C30" offset="0%"></stop>
          <stop stop-color="#351D1D" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M23,0 C35.7025492,0 46,10.2974508 46,23 C46,35.7025492 35.7025492,46 23,46 C10.2974508,46 0,35.7025492 0,23 C0,10.2974508 10.2974508,0 23,0 Z" id="a" fill="#FFB851" fill-rule="nonzero"></path>
        <path d="M21.1805,20.005 L25.3125,20.005 C30.797516,20.005 35.244,24.451484 35.244,29.9365 C35.244,35.421516 30.797516,39.868 25.3125,39.868 L21.1805,39.868 C15.695484,39.868 11.249,35.421516 11.249,29.9365 C11.249,24.451484 15.695484,20.005 21.1805,20.005 Z" id="Rectangle" fill="url(#linearGradient-mr9ul6bi4--1)" fill-rule="nonzero"></path>
        <path d="M12.023,33.785 C15.5516987,35.3716544 19.3780052,36.1881195 23.247,36.18 C27.06505,36.18 30.6908675,35.40385 33.9589791,34.0101644 L34.47,33.7859788 L34.3509132,34.0590954 C32.7875211,37.4867967 29.4161922,39.7347428 25.6534065,39.8622743 L23.972,41.452 C23.5630073,41.8330698 22.9289927,41.8330698 22.52,41.452 L20.8396816,39.8621709 C16.978054,39.7301455 13.5291192,37.3656911 12.023,33.785 Z M12.337,25.411 C15.7794884,26.9063378 19.4937737,27.674388 23.247,27.667 C27.141,27.667 30.837,26.859 34.156,25.412 C34.8709756,26.8075013 35.244,28.3530032 35.244,29.921 C31.4263188,31.4386705 27.3542791,32.2145234 23.246,32.2071076 C18.992,32.2071076 14.938,31.393 11.249,29.921 C11.249,28.3526788 11.6219786,26.8068423 12.337,25.411 Z M25.311,20.0049788 C27.8611085,20.001626 30.3141646,20.9824186 32.159,22.743 C29.337,23.546 26.346,23.9780391 23.246,23.9780391 C20.2317987,23.9813162 17.2317637,23.5656731 14.332,22.743 C16.1770284,20.9820908 18.6305261,20.0012649 21.181,20.0049788 L25.311,20.0049788 Z" id="Shape" fill="url(#linearGradient-mr9ul6bi4--2)" fill-rule="nonzero"></path>
        <path d="M23.546,20.005 C23.546,12.953 29.589,7.236 37.043,7.236 C37.043,14.288 31,20.006 23.546,20.006 L23.546,20.005 Z M9.45,7.236 C16.904,7.236 22.946,12.953 22.946,20.006 C15.492,20.006 9.449,14.288 9.449,7.236 L9.45,7.236 Z" id="Shape" fill="#FFFFFF" fill-rule="nonzero"></path>
      </g>
    </SvgIcon>
  )
}
