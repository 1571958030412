import { gql } from '@apollo/client';

// 获取推荐列表
export const GET_EXPLORE = gql`
  query Query {
    explore {
      id
      collectionId
      description
      collection {
        id
        name
        bookmarks {
          url
          image
          updatedAt
        }
        count
      }
    }
  }
`;
