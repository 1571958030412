import emojiRegex from 'emoji-regex';
import { MD5 } from 'crypto-js';
import { URL_CARD_HOST } from '@constants/url';

// 获取字符串中emoji图标
const getEmojiAndName = (str) => {
  if (!str) {
    return [null, ''];
  }
  
  let icon;
  const regex = emojiRegex();
  let name = str.replace(regex, (emoji, pos) => {
    if (pos === 0) {
      icon = emoji;
      return '';
    }
    return emoji;
  });
  return [icon, name];
}

const isProduction = () => location.hostname === 'kiipu.com';

const getImageUrlHost = () => isProduction() || process.env.PRODUCTION ? '//img.kiipu.com' : '//img-dev.kiipu.com';

const getFaviconUrlHost = () => isProduction() || process.env.PRODUCTION ? '//favicon.kiipu.com' : '//favicon-dev.kiipu.com';

const getCardUrl = (name, version) => {
  console.log('[process.env.DEBUG_CARD]', process.env.DEBUG_CARD);
  if (process.env.DEBUG_CARD) {
    return `//localhost:9999/src/cards/${name}.js?_t=${new Date().getTime()}`;
  }
  return `${URL_CARD_HOST}/${name}/${version}/${name}.js`;
}

const getContrastYIQ = (hexcolor) => {
  var r = parseInt(hexcolor.substring(1,3),16);
  var g = parseInt(hexcolor.substring(3,5),16);
  var b = parseInt(hexcolor.substring(5,7),16);
  var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return (yiq >= 190) ? '#212B36' : '#FFFFFF';
}

const getReadableTime = (dateTime) => {
  // 用毫秒表示分钟、小时、天、周、月
  let minute = 1000 * 60;
  let hour = minute * 60;
  let day = hour * 24;
  let week = day * 7;
  let month = day * 30;

  // 获取当前时间并转换为时间戳，方便计算
	let timestamp_current = new Date().getTime();
 
	// 将传入的时间格式字符串解析为Date对象
	let _date = new Date(dateTime);
 
	// 将Date对象转换为时间戳，方便计算
	let timestamp_input = _date.getTime();
 
	// 计算当前时间与传入的时间之间相差的时间戳
	let timestamp_diff = timestamp_current - timestamp_input;
 
	// 计算时间差共有多少个分钟
	let minC = timestamp_diff / minute;
	// 计算时间差共有多少个小时
	let hourC = timestamp_diff / hour;
	// 计算时间差共有多少个天
	let dayC = timestamp_diff / day;
	// 计算时间差共有多少个周
	let weekC = timestamp_diff / week;
	// 计算时间差共有多少个月
	let monthC = timestamp_diff / month;
 
	/*if (monthC >= 1 && monthC < 4) {
		return parseInt(monthC) + '个月前';
	} else if (weekC >= 1 && weekC < 4) {
		return parseInt(weekC) + '周前';
	} else */if (dayC >= 1 && dayC < 7) {
		return parseInt(dayC) + '天前';
	} else if (hourC >= 1 && hourC < 24) {
		return parseInt(hourC) + '小时前';
	} else if (minC >= 10 && minC < 60) {
		return parseInt(minC) + '分钟前';
	} else if ((timestamp_diff >= 0) && (minC < 10)) {
		return '刚刚';
	} else {
		return ((new Date().getFullYear() != _date.getFullYear()) ? _date.getFullYear() + '年' : '') + (_date.getMonth() + 1) + '月' + _date.getDate() + '日';
	}
}

const getImageUrl = (imageUrl, url, clip) => {
  let clipStr = '';
  if (clip) {
    clipStr = `-${clip}`;
  }
  return `${getImageUrlHost()}/${new URL(url).hostname}/${MD5(imageUrl.replace(/.*:?\/\//, ''))}${clipStr}`;
}

const getFaviconUrl = (url) => {
  return `${getFaviconUrlHost()}/${(new URL(url)).host}`;
}

const getToken = () => new Promise((resolve, reject) => {
  if (process.env.CHROME_EXT) {
    chrome.cookies.get({
      url: process.env.WEB_HOST,
      name: 'token'
    }, cookies => {
      if (cookies && cookies.value) {
        resolve(cookies.value);
      } else {
        resolve();
      }
    })
  } else {
    resolve(localStorage.getItem('token'));
  }
});

const getChromeExtensionId = () => {
  return localStorage['_kp_chrome_extension_id'] || (window.location.host.startsWith('dev') ? 'kddenfladaegmjfjgmamhoofjonpllec' : 'fjhogephchakgfmjjgiendejbblfgmhn');
};

// 获取文本中的URL链接
const extractUrls = (val) => {
  const reg = /https?:\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;
  return val.match(reg);
}

export {
  getEmojiAndName,
  isProduction,
  getImageUrlHost,
  getFaviconUrlHost,
  getCardUrl,
  getContrastYIQ,
  getReadableTime,
  getImageUrl,
  getFaviconUrl,
  getToken,
  getChromeExtensionId,
  extractUrls,
}
